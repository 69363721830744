import './Navbar.css';
function Navbar () {
    return (
        <div class="bg-violet-500 flex flex-row gap-4">
            <div class="basis-1/3">
                Timothy Taggart
            </div>
            <div class="flex flex-row basis-1/3 justify-center">
                <div class="basis-1/3">
                    About
                </div>
                <div class="basis-1/3">
                    Projects
                </div>
                <div class="basis-1/3">
                    Experience
                </div>
            </div>
            <div class="basis-1/3">
                <div class="top-10"> Hello</div>
            </div>
        </div>
    )
}

export default Navbar;