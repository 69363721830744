import './App.css';
import Navbar from "./components/Navbar/Navbar";

function App() {
  return (
    <div>
        <div>
            <Navbar />
        </div>
      <h2>TIMOTHY WEBSITE</h2>
      <p>CONTENT OTW</p>
    </div>
  );
}

export default App;
